import * as React from 'react'
import loadable from '@loadable/component'

const Select = loadable(() => import('/src/components/Form/Select'))

const SelectYear = ({
    name,
    minAge,
    maxAge,
    isSubmitting,
    isSubmitted,
    className
}) => {
    const maxAgeOffset = maxAge - minAge

    const year = new Date().getFullYear() - minAge
    const yearRange = (start, stop, step) =>
        Array.from(
            { length: (stop - start) / step + 1 },
            (_, i) => start + i * step
        )
    const years = yearRange(year, year - maxAgeOffset, -1)

    return (
        <Select
            className={className}
            name={name}
            id={name}
            firstItem={'Select year'}
            data={years}
            isSubmitting={isSubmitting}
            isSubmitted={isSubmitted}
        />
    )
}

export default SelectYear
